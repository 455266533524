



























































































import { Component, Inject } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import Multiselect from "vue-multiselect";
import { mixins } from "vue-class-component";

import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";
import FormField from "@/lib/components/FormField.vue";
import FormErrors from "@/lib/components/FormErrors.vue";

import dataStore from "@/store";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";
import FactoryStore from "@/modules/factory/store";
import Factory from "@/modules/factory/factory.model";
import Product from "@/modules/plushie/product.model";
import PlushieStore from "@/modules/plushie/store";

import HolidayPeriod from "../../holiday-period.model";
import HolidayPeriodStore from "../../store";
import CutoffReportDownloadJobRepository from "../../cutoff-report-download-job.repository";

@Component({
  components: {
    LoadingSpinner,
    SubmitButton,
    FormField,
    FormErrors,
    Multiselect,
  },
})
export default class HolidayCutoffReportForm extends mixins(GeneralFormMixin) {
  @Inject("CutoffReportDownloadJobRepository")
  private fCutoffReportDownloadJobRepository!: CutoffReportDownloadJobRepository;

  @Inject("window")
  private fWindow!: Window;

  public period?: HolidayPeriod;
  public factories: Factory[] = [];
  public products: Product[] = [];

  private fHolidayPeriodStore: HolidayPeriodStore;
  private fPlushieStore: PlushieStore;
  private fFactoryStore: FactoryStore;

  get periodOptions(): HolidayPeriod[] {
    return this.fHolidayPeriodStore.holidayPeriodsList;
  }

  get factoryOptions(): Factory[] {
    return this.fFactoryStore.factoriesList;
  }

  get productOptions(): Product[] {
    return this.fPlushieStore.productsList;
  }

  public constructor() {
    super();

    this.fHolidayPeriodStore = getModule(HolidayPeriodStore, dataStore);
    this.fPlushieStore = getModule(PlushieStore, dataStore);
    this.fFactoryStore = getModule(FactoryStore, dataStore);
  }

  protected async created(): Promise<void> {
    await Promise.all([
      this.fHolidayPeriodStore.loadHolidayPeriods(),
      this.fFactoryStore.loadFactories(),
      this.fPlushieStore.loadProducts(),
    ]);
  }

  protected async performSubmit(): Promise<void> {
    if (!this.period || !this.products) {
      throw new Error("Not all required fields are filled!");
    }

    const factoriesIds = this.factories.map((factory: Factory) => factory.id);
    const productsIds = this.products.map((product: Product) => product.id);

    const downloadUrl = await this.fCutoffReportDownloadJobRepository.createDownloadJob(
      this.period.id,
      factoriesIds,
      productsIds
    );

    this.fWindow.location.href = downloadUrl;
  }

  protected data(): Record<string, unknown> {
    return {
      period: this.period,
    };
  }
}
