import Vue from "vue";
import { Module, VuexModule, Mutation } from "vuex-module-decorators";

import { Dictionary } from "@/lib/Dictionary.type";
import dataStore from "@/store";

export const name = "UiPersisterStore";

const getDefaultState = () => {
  return {
    plushieReviewComment: {},
    plushieRejectComment: {},
    plushieRejectionReasonIds: {},
    plushieMessage: {},
    productionTimeAlertDaysCount: 7,
  };
};

@Module({ name })
export class UiPersisterStore extends VuexModule {
  plushieReviewComment: Dictionary<string> = {};
  plushieRejectComment: Dictionary<string> = {};
  plushieRejectionReasonIds: Dictionary<string[]> = {};
  plushieMessage: Dictionary<string> = {};
  productionTimeAlertDaysCount = 7;

  // ################################### PLUSHIE REVIEW COMMENT #########################################

  get getPlushieReviewComment(): (id: string) => string | undefined {
    return (id: string) => {
      return this.plushieReviewComment[id];
    };
  }

  @Mutation
  updatePlushieReviewComment({
    plushieId,
    comment,
  }: {
    plushieId: string;
    comment: string;
  }): void {
    Vue.set(this.plushieReviewComment, plushieId, comment);
  }

  @Mutation
  deletePlushieReviewComment({ plushieId }: { plushieId: string }): void {
    Vue.delete(this.plushieReviewComment, plushieId);
  }

  // ################################### PLUSHIE REJECT COMMENT #########################################

  get getPlushieRejectComment(): (id: string) => string | undefined {
    return (id: string) => {
      return this.plushieRejectComment[id];
    };
  }

  @Mutation
  updatePlushieRejectComment({
    plushieId,
    comment,
  }: {
    plushieId: string;
    comment: string;
  }): void {
    Vue.set(this.plushieRejectComment, plushieId, comment);
  }

  @Mutation
  deletePlushieRejectComment({ plushieId }: { plushieId: string }): void {
    Vue.delete(this.plushieRejectComment, plushieId);
  }

  // ################################### PLUSHIE REJECTION REASON IDS #########################################

  get getPlushieRejectionReasonIds(): (id: string) => string[] | undefined {
    return (id: string) => {
      return this.plushieRejectionReasonIds[id];
    };
  }

  @Mutation
  updatePlushieRejectionReasonIds({
    plushieId,
    ids,
  }: {
    plushieId: string;
    ids: string[];
  }): void {
    Vue.set(this.plushieRejectionReasonIds, plushieId, ids);
  }

  @Mutation
  deletePlushieRejectionReasonIds({ plushieId }: { plushieId: string }): void {
    Vue.delete(this.plushieRejectionReasonIds, plushieId);
  }

  // ################################### PLUSHIE MESSAGE #########################################

  get getPlushieMessage(): (id: string) => string | undefined {
    return (id: string) => {
      return this.plushieMessage[id];
    };
  }

  @Mutation
  updatePlushieMessage({
    plushieId,
    message,
  }: {
    plushieId: string;
    message: string;
  }): void {
    Vue.set(this.plushieMessage, plushieId, message);
  }

  @Mutation
  deletePlushieMessage({ plushieId }: { plushieId: string }): void {
    Vue.delete(this.plushieMessage, plushieId);
  }

  // ################################### Production Time Alert Report #########################################

  @Mutation
  updateProductionTimeAlertDaysCount(daysCount: number): void {
    this.productionTimeAlertDaysCount = daysCount;
  }

  // ################################### DATA WIPING #########################################

  @Mutation
  resetState(): void {
    const state = (dataStore.state as any)[name];

    if (state) {
      Object.assign(state, getDefaultState());
    }
  }
}
