





















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import MoonLoader from "vue-spinner/src/MoonLoader.vue";

import dataStore from "@/store";
import FileStorageStore from "@/modules/file-storage/store";
import PlushieStore from "@/modules/plushie/store";

import BodyPartEditFormItem from "./BodyPartEditForm/Item.vue";

import BodyPartStore from "../store";
import Plushie from "../../plushie/plushie.model";
import BodyPart from "../body-part.model";

@Component({
  components: {
    MoonLoader,
    BodyPartEditFormItem,
  },
})
export default class BodyPartsEditForm extends Vue {
  @Prop({ required: true })
  public readonly plushieId!: string;

  private fPlushie!: Plushie;
  private fIsLoading = true;

  private fBodyPartDataStore: BodyPartStore;
  private fPlushieDataStore: PlushieStore;
  private fFileStorageDataStore: FileStorageStore;

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get bodyParts(): BodyPart[] {
    if (!this.plushie) {
      return [];
    }

    return this.fBodyPartDataStore.getEnabledBodyPartsByProductId(
      this.plushie.product
    );
  }

  get mainBodyParts(): BodyPart[] {
    return this.bodyParts.filter((bodyPart) => !bodyPart.parentBodyPart);
  }

  get plushie(): Plushie {
    return this.fPlushie;
  }

  constructor() {
    super();
    this.fBodyPartDataStore = getModule(BodyPartStore, dataStore);
    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
    this.fFileStorageDataStore = getModule(FileStorageStore, dataStore);
  }

  public getChildrenBodyPartsForBodyPart(bodyPart: BodyPart): BodyPart[] {
    if (!bodyPart.canBeDetailed) {
      return [];
    }

    return this.bodyParts.filter((item) => item.parentBodyPart === bodyPart.id);
  }

  private async getPlushieData(plushieId: string) {
    const plushie = await this.fPlushieDataStore.loadPlushieById({
      id: plushieId,
    });

    if (!plushie) {
      return;
    }

    this.fPlushie = plushie;

    const bodyParts = await this.fBodyPartDataStore.loadBodyPartsByProductId({
      productId: plushie.product,
    });

    if (!bodyParts) {
      return;
    }

    const bodyPartIds = bodyParts.map((bodyPart) => bodyPart.id);

    const [bodyPartValues] = await Promise.all([
      this.fBodyPartDataStore.loadValuesByBodyPartIds({
        bodyPartIds,
      }),
      this.fBodyPartDataStore.loadPlushieValueRelationsByPlushieId({
        plushieId: this.plushieId,
      }),
    ]);

    const valueStoreItemIds: string[] = [];
    for (const bodyPartId in bodyPartValues) {
      const values = bodyPartValues[bodyPartId];

      if (!values) {
        continue;
      }

      values.forEach((value) => {
        if (!value.storageItem) {
          return;
        }

        valueStoreItemIds.push(value.storageItem);
      });
    }

    await this.fFileStorageDataStore.loadItemsByIds(valueStoreItemIds);
  }

  @Watch("plushieId", { immediate: true })
  private async _onPlushieIdChange() {
    if (!this.plushieId) {
      return;
    }

    this.fIsLoading = true;

    await this.getPlushieData(this.plushieId);

    this.fIsLoading = false;
  }
}
