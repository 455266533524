














import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import PlushieAttachmentDownloaderService from "@/modules/file-storage/plushie-attachment-downloader-service";
import FileStorageStore from "@/modules/file-storage/store";
import store from "@/store";

import PlushieAttachment from "../../plushie-attachment.model";
import PlushieStorageItemNameResolverService from "../../plushie-storage-item-name-resolver.service";
import PlushieStore from "../../store";
import Plushie from "../../plushie.model";

@Component
export default class PlushieAttachmentListItem extends Vue {
  @Prop({ required: true })
  public readonly item!: PlushieAttachment;

  @Inject("PlushieAttachmentDownloaderService")
  private fAttachmentDownloaderService!: PlushieAttachmentDownloaderService;

  @Inject("PlushieStorageItemNameResolverService")
  private fPlushieStorageItemNameResolverService!: PlushieStorageItemNameResolverService;

  private fIsDownloading = false;

  private fFileStorageStore!: FileStorageStore;
  private fPlushieStore!: PlushieStore;

  get isDisabled(): boolean {
    return this.fIsDownloading;
  }

  get name(): string {
    const attachmentType = this.fPlushieStore.attachmentType[this.item.type];

    let name = attachmentType ? attachmentType.code : "attachment";
    name = name.replace(/_/g, " ");
    name = name.charAt(0).toUpperCase() + name.slice(1);

    return name;
  }

  get plushie(): Plushie {
    const plushie = this.fPlushieStore.getPlushieById(this.item.plushie);

    if (!plushie) {
      throw new Error("Plushie is not loaded");
    }

    return plushie;
  }

  constructor() {
    super();
    this.fFileStorageStore = getModule(FileStorageStore, store);
    this.fPlushieStore = getModule(PlushieStore, store);
  }

  public async downloadAttachment(): Promise<void> {
    if (this.fIsDownloading) {
      return;
    }

    this.fIsDownloading = true;

    const storageItem = this.fFileStorageStore.getItemById(
      this.item.storageItem
    );

    if (!storageItem) {
      return;
    }

    const attachmentName = this.fPlushieStorageItemNameResolverService.resolve(
      storageItem,
      this.plushie
    );

    try {
      await this.fAttachmentDownloaderService.download(
        this.item,
        attachmentName
      );
    } finally {
      this.fIsDownloading = false;
    }
  }
}
