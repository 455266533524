import { getModule } from "vuex-module-decorators";
import { saveAs } from "file-saver";

import dataStore from "@/store";

import FileStorageStore from "./store";
import StorageItemReferenceInterface from "./storage-item-reference.interface";
import VideoHandlerService from "./video-handler.service";

export default class VideoDownloaderService {
  private fVideoHandlerService: VideoHandlerService;

  private fFileStorageDataStore: FileStorageStore;

  public constructor(videoHandlerService: VideoHandlerService) {
    this.fVideoHandlerService = videoHandlerService;
    this.fFileStorageDataStore = getModule(FileStorageStore, dataStore);
  }

  public async download(
    video: StorageItemReferenceInterface,
    fileName: string
  ): Promise<void> {
    const storageItem = this.fFileStorageDataStore.getItemById(
      video.storageItem
    );

    if (!storageItem) {
      return;
    }

    const originalUrl = this.fVideoHandlerService.getOriginalVideoUrl(
      storageItem.url
    );

    const response = await fetch(originalUrl);

    if (!response.ok) {
      throw new Error("Fail to download the video");
    }

    const blobBody = await response.blob();

    saveAs(blobBody, fileName);
  }
}
