import { AxiosResponse } from "axios";

import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import Plushie from "@/modules/plushie/plushie.model";
import { ResourceCollection } from "@/modules/api/resource.collection";

import { Command } from "./command";

export default class ProductionProcessRepository {
  constructor(
    private api: ApiService,
    private plushieBuilder: ObjectBuilderInterface<Plushie>,
    private plushieResponseParser: ApiCollectionResponseParserService<Plushie>
  ) {}

  public async getAvailableCommands(plushieId: string): Promise<Set<Command>> {
    if (!plushieId) {
      throw new Error("Plushie Id is required!");
    }

    const response = await this.api.get(`plushies/${plushieId}/commands`);

    return new Set<Command>(Object.keys(response.data) as Command[]);
  }

  public async approveCustomerPreview(
    plushieId: string
  ): Promise<AxiosResponse> {
    return this.api.post("customer_previews/approvements", {
      plushieId,
    });
  }

  public async cancelPlushie(plushieId: string): Promise<Plushie> {
    const result = await this.api.post("plushies/canceled", [
      { plushie: plushieId },
    ]);

    return this.plushieBuilder.buildFromJSON(result.data["hydra:member"][0]);
  }

  public async moveToDesign(plushieId: string): Promise<Plushie> {
    const result = await this.api.post("plushies/in_design", [
      { plushie: plushieId },
    ]);

    return this.plushieBuilder.buildFromJSON(result.data["hydra:member"][0]);
  }

  public async bulkMoveToDesign(
    plushieIds: string[]
  ): Promise<ResourceCollection<Plushie>> {
    const data = plushieIds.map((plushie) => ({
      plushie,
    }));

    const response = await this.api.post("plushies/in_design", data);

    return this.plushieResponseParser.parse(response.data, this.plushieBuilder);
  }

  public async moveToProduction(plushieId: string): Promise<Plushie> {
    const result = await this.api.post("plushies/in_production", [
      { plushie: plushieId },
    ]);

    return this.plushieBuilder.buildFromJSON(result.data["hydra:member"][0]);
  }

  public async prepareForShipment(plushieId: string): Promise<Plushie> {
    const result = await this.api.post("plushies/ready_for_shipment", [
      { plushie: plushieId },
    ]);

    return this.plushieBuilder.buildFromJSON(result.data["hydra:member"][0]);
  }

  public async bulkMoveToProduction(
    plushieIds: string[]
  ): Promise<ResourceCollection<Plushie>> {
    const data = plushieIds.map((plushie) => ({
      plushie,
    }));

    const response = await this.api.post("plushies/in_production", data);

    return this.plushieResponseParser.parse(response.data, this.plushieBuilder);
  }

  public async bulkPrepareToShipment(
    plushieIds: string[]
  ): Promise<ResourceCollection<Plushie>> {
    const data = plushieIds.map((plushie) => ({
      plushie,
    }));

    const response = await this.api.post("plushies/ready_for_shipment", data);

    return this.plushieResponseParser.parse(response.data, this.plushieBuilder);
  }

  public async moveToQualityInspection(plushieId: string): Promise<Plushie> {
    const result = await this.api.post("plushies/in_quality_inspection", [
      { plushie: plushieId },
    ]);

    return this.plushieBuilder.buildFromJSON(result.data["hydra:member"][0]);
  }

  public async moveToReview(plushieId: string): Promise<Plushie> {
    const result = await this.api.post("plushies/under_review", [
      { plushie: plushieId },
    ]);

    return this.plushieBuilder.buildFromJSON(result.data["hydra:member"][0]);
  }

  public async putOnHold(plushieId: string): Promise<Plushie> {
    const result = await this.api.post("plushies/on_hold", [
      { plushie: plushieId },
    ]);

    return this.plushieBuilder.buildFromJSON(result.data["hydra:member"][0]);
  }

  public async reassignFactory(
    plushieId: string,
    newFactoryId: string
  ): Promise<Plushie> {
    const result = await this.api.post("plushie_factory_relations", [
      { plushie: plushieId, factory: newFactoryId },
    ]);

    return this.plushieBuilder.buildFromJSON(result.data["hydra:member"][0]);
  }

  public async bulkReassignFactory(
    plushieIds: string[],
    factory: string
  ): Promise<ResourceCollection<Plushie>> {
    const data = plushieIds.map((plushie) => ({
      plushie,
      factory,
    }));

    const response = await this.api.post("plushie_factory_relations", data);

    return this.plushieResponseParser.parse(response.data, this.plushieBuilder);
  }

  public async returnFromCustomerPreview(plushieId: string): Promise<Plushie> {
    const result = await this.api.post(
      "plushies/returned_to_quality_inspection_from_customer_preview",
      [{ plushie: plushieId }]
    );

    return this.plushieBuilder.buildFromJSON(result.data["hydra:member"][0]);
  }

  public async returnToDesign(plushieId: string): Promise<Plushie> {
    const result = await this.api.post("plushies/returned_to_design", [
      { plushie: plushieId },
    ]);

    return this.plushieBuilder.buildFromJSON(result.data["hydra:member"][0]);
  }

  public async returnToQualityInspection(plushieId: string): Promise<Plushie> {
    const result = await this.api.post(
      "plushies/returned_to_quality_inspection",
      [{ plushie: plushieId }]
    );

    return this.plushieBuilder.buildFromJSON(result.data["hydra:member"][0]);
  }

  public async returnToCustomerPreview(plushieId: string): Promise<Plushie> {
    const result = await this.api.post("customer-previews/in-progress", [
      { plushie: plushieId },
    ]);

    return this.plushieBuilder.buildFromJSON(result.data["hydra:member"][0]);
  }

  public async reviewApprove(
    plushieId: string,
    comment?: string
  ): Promise<Plushie> {
    const result = await this.api.post("plushies/review_approved", [
      { plushie: plushieId, comment: comment ?? "" },
    ]);

    return this.plushieBuilder.buildFromJSON(result.data["hydra:member"][0]);
  }

  public async reviewApproveAndSkipDesign(
    plushieId: string,
    comment?: string
  ): Promise<Plushie> {
    const result = await this.api.post(
      "plushies/review_approved_and_skip_design",
      [{ plushie: plushieId, comment: comment ?? "" }]
    );

    return this.plushieBuilder.buildFromJSON(result.data["hydra:member"][0]);
  }

  public async forceQualityInspectionApprove(
    plushieId: string
  ): Promise<Plushie> {
    const result = await this.api.post(
      "plushies/quality_inspection_force_approved",
      [{ plushie: plushieId }]
    );

    return this.plushieBuilder.buildFromJSON(result.data["hydra:member"][0]);
  }

  public async skipShipment(plushieId: string): Promise<Plushie> {
    const result = await this.api.post("plushies/finished_without_shipping", [
      { plushie: plushieId },
    ]);

    return this.plushieBuilder.buildFromJSON(result.data["hydra:member"][0]);
  }

  // Batches commands
  public async approveBulKPreview(plushieId: string): Promise<Plushie> {
    const result = await this.api.post("plushies/bulk_preview_approved", [
      { plushie: plushieId },
    ]);

    return this.plushieBuilder.buildFromJSON(result.data["hydra:member"][0]);
  }

  public async approvePpsPreview(plushieId: string): Promise<Plushie> {
    const result = await this.api.post("plushies/pps_preview_approved", [
      { plushie: plushieId },
    ]);

    return this.plushieBuilder.buildFromJSON(result.data["hydra:member"][0]);
  }

  public async confirmBulkShipment(plushieId: string): Promise<Plushie> {
    const result = await this.api.post("plushies/bulk_shipment_confirmed", [
      { plushie: plushieId },
    ]);

    return this.plushieBuilder.buildFromJSON(result.data["hydra:member"][0]);
  }

  public async moveToBulkInspection(plushieId: string): Promise<Plushie> {
    const result = await this.api.post("plushies/in_bulk_inspection", [
      { plushie: plushieId },
    ]);

    return this.plushieBuilder.buildFromJSON(result.data["hydra:member"][0]);
  }

  public async moveToPpsInspection(plushieId: string): Promise<Plushie> {
    const result = await this.api.post("plushies/in_pps_inspection", [
      { plushie: plushieId },
    ]);

    return this.plushieBuilder.buildFromJSON(result.data["hydra:member"][0]);
  }

  public async sendToCustomer(
    plushieId: string,
    trackingNumber?: string
  ): Promise<Plushie> {
    const result = await this.api.post("plushies/sent_to_customer", [
      { plushie: plushieId, trackingNumber },
    ]);

    return this.plushieBuilder.buildFromJSON(result.data["hydra:member"][0]);
  }

  public async startSampleProduction(payload: {
    plushie: string;
    factoryIdToAssign: string;
    taskDetails: string;
    expectedDueDate: string;
    negotiatedPrice: number;
  }): Promise<Plushie> {
    const result = await this.api.post("plushies/in_bulk_design", [payload]);

    return this.plushieBuilder.buildFromJSON(result.data["hydra:member"][0]);
  }

  public async moveToDirectShipment(plushieId: string): Promise<Plushie> {
    const result = await this.api.post("plushies/ready_for_direct_shipment", [
      { plushie: plushieId },
    ]);

    return this.plushieBuilder.buildFromJSON(result.data["hydra:member"][0]);
  }

  public async returnIntoPreparedToShipment(
    plushieId: string
  ): Promise<Plushie> {
    const result = await this.api.post(
      "plushies/returned_into_prepared_to_shipment",
      [{ plushie: plushieId }]
    );

    return this.plushieBuilder.buildFromJSON(result.data["hydra:member"][0]);
  }
}
