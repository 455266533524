import Vue from "vue";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

import { DIContainer } from "@/app.container";
import { Dictionary } from "@/lib/Dictionary.type";
import { DirectoryValue } from "@/modules/api/directory-value.model";
import dataStore from "@/store";

import OrderInformation from "../order-information.model";
import Plushie from "../plushie.model";
import PlushieDesignInformation from "../plushie-design-information.model";
import PlushieImage from "../plushie-image.model";
import PlushieImageType from "../plushie-image-type.model";
import PlushieStatus from "../plushie-status.model";
import Product from "../product.model";
import Upgrade from "../upgrade.model";
import PlushieInformationUpdate from "../plushie-information-update.interface";
import PlushieDesignInformationUpdate from "../plushie-design-information-update.interface";
import PlushieArbitraryAttribute from "../plushie-arbitrary-attribute.model";
import ShippingInformation from "../shipping-information.model";
import TrackingInformation from "../tracking-information.model";
import PlushieAttachment from "../plushie-attachment.model";
import PlushieAttachmentType from "../plushie-attachment-type.model";

const name = "PlushieStore";

if ((dataStore.state as any)[name]) {
  dataStore.unregisterModule(name);
}

const getDefaultState = () => {
  return {
    designInformation: {},
    oderIdOrderInformations: {},
    orderInformation: {},
    plushie: {},
    plushieStatus: {},
    product: {},
    sellingStore: {},
    upgrade: {},
    plushieUpgrade: {},
    image: {},
    plushieImage: {},
    plushieMainImage: {},
    arbitraryAttribute: {},
    plushieArbitraryAttribute: {},
    shippingInformation: {},
    trackingInformation: {},
    attachment: {},
    attachmentType: {},
  };
};

@Module({ name, dynamic: true, store: dataStore })
export default class PlushieStore extends VuexModule {
  designInformation: Dictionary<PlushieDesignInformation> = {};
  oderIdOrderInformations: Dictionary<string[]> = {};
  orderInformation: Dictionary<OrderInformation> = {};
  plushie: Dictionary<Plushie> = {};
  plushieStatus: Dictionary<PlushieStatus> = {};
  product: Dictionary<Product> = {};
  sellingStore: Dictionary<DirectoryValue> = {};
  upgrade: Dictionary<Upgrade> = {};
  plushieUpgrade: Dictionary<string[]> = {};
  image: Dictionary<PlushieImage> = {};
  plushieImage: Dictionary<string[]> = {};
  plushieMainImage: Dictionary<string> = {};
  arbitraryAttribute: Dictionary<PlushieArbitraryAttribute> = {};
  plushieArbitraryAttribute: Dictionary<string[]> = {};
  shippingInformation: Dictionary<ShippingInformation> = {};
  trackingInformation: Dictionary<TrackingInformation> = {};
  attachment: Dictionary<PlushieAttachment> = {};
  plushieAttachment: Dictionary<string[]> = {};
  attachmentType: Dictionary<PlushieAttachmentType> = {};

  // ################################### DESIGN INFORMATION #########################################

  get getDesignInformationByPlushieId(): (
    id: string
  ) => PlushieDesignInformation | undefined {
    return (id: string) => this.designInformation[id];
  }

  @Mutation
  updateDesignInformation(payload: PlushieDesignInformation): void {
    Vue.set(this.designInformation, payload.id, payload);
  }

  @Action({ rawError: true })
  async loadDesignInformationByPlushieId({
    plushieId,
    useCache = true,
  }: {
    plushieId: string;
    useCache?: boolean;
  }): Promise<PlushieDesignInformation | undefined> {
    if (useCache && this.designInformation[plushieId]) {
      return this.designInformation[plushieId];
    }

    const designInformation = await DIContainer.PlushieDesignInformationRepository.getById(
      plushieId
    );

    if (designInformation) {
      this.updateDesignInformation(designInformation);
    }

    return designInformation;
  }

  @Action({ rawError: true })
  async updatePlushieDesignInformation(
    plushieDesignInformationUpdate: PlushieDesignInformationUpdate
  ): Promise<PlushieDesignInformation> {
    const item = await DIContainer.PlushieDesignInformationRepository.updatePlushieDesignInformation(
      plushieDesignInformationUpdate
    );

    this.updateDesignInformation(item);

    return item;
  }

  // ################################### ORDER INFORMATION #########################################

  get getOrderInformationByPlushieId(): (
    id: string
  ) => OrderInformation | undefined {
    return (id: string) => this.orderInformation[id];
  }

  get getOrderInformationsByOrderId(): (orderId: string) => OrderInformation[] {
    return (orderId: string) => {
      const result: OrderInformation[] = [];

      this.oderIdOrderInformations[orderId].forEach((id) => {
        result.push(this.orderInformation[id]);
      });

      return result;
    };
  }

  get getOrderInformationsByOrderIds(): (
    orderIds: string[]
  ) => OrderInformation[] {
    return (orderIds: string[]) => {
      const result: OrderInformation[] = [];

      orderIds.forEach((orderId) => {
        if (!this.oderIdOrderInformations[orderId]) {
          return;
        }

        this.oderIdOrderInformations[orderId].forEach((id) => {
          result.push(this.orderInformation[id]);
        });
      });

      return result;
    };
  }

  @Mutation
  updateOrderInformation(payload: OrderInformation): void {
    Vue.set(this.orderInformation, payload.id, payload);
  }

  @Mutation
  updateOrderIdOrderInformations({
    orderId,
    orderInformations,
  }: {
    orderId: string;
    orderInformations: OrderInformation[];
  }): void {
    const orderInformationIds: string[] = [];
    orderInformations.forEach((item) => {
      orderInformationIds.push(item.id);
      Vue.set(this.orderInformation, item.id, item);
    });

    Vue.set(this.oderIdOrderInformations, orderId, orderInformationIds);
  }

  @Action({ rawError: true })
  async loadOrderInformationsByOrderId(
    orderId: string
  ): Promise<OrderInformation[]> {
    if (this.oderIdOrderInformations[orderId]) {
      const result: OrderInformation[] = [];

      this.oderIdOrderInformations[orderId].forEach((id) => {
        result.push(this.orderInformation[id]);
      });

      return result;
    }

    const collection = await DIContainer.OrderInformationRepository.getByOrderId(
      orderId
    );

    const orderInformations = collection.getItems();

    this.updateOrderIdOrderInformations({
      orderId,
      orderInformations,
    });

    return orderInformations;
  }

  @Action({ rawError: true })
  async loadOrderInformationsByOrderIds(
    orderIds: string[]
  ): Promise<Dictionary<OrderInformation[]>> {
    const missing: string[] = [];
    const result: Dictionary<OrderInformation[]> = {};

    orderIds.forEach((orderId) => {
      if (!this.oderIdOrderInformations[orderId]) {
        missing.push(orderId);
        return;
      }

      result[orderId] = [];

      this.oderIdOrderInformations[orderId].forEach((id) => {
        result[orderId].push(this.orderInformation[id]);
      });
    });

    if (!missing.length) {
      return result;
    }

    const collection = await DIContainer.OrderInformationRepository.getByOrderIds(
      missing
    );

    const orderInformations = collection.getItems();

    const oderIdInformations: Dictionary<OrderInformation[]> = {};

    orderInformations.forEach((orderInformation) => {
      if (oderIdInformations[orderInformation.orderId] === undefined) {
        oderIdInformations[orderInformation.orderId] = [];
      }

      oderIdInformations[orderInformation.orderId].push(orderInformation);
    });

    Object.keys(oderIdInformations).forEach((orderId) => {
      const items = oderIdInformations[orderId];

      result[orderId] = items;

      this.updateOrderIdOrderInformations({
        orderId,
        orderInformations: items,
      });
    });

    return result;
  }

  @Action({ rawError: true })
  async loadOrderInformationByPlushieId(
    id: string
  ): Promise<OrderInformation | undefined> {
    if (this.orderInformation[id]) {
      return this.orderInformation[id];
    }

    const orderInformation = await DIContainer.OrderInformationRepository.getById(
      id
    );

    if (orderInformation) {
      this.updateOrderInformation(orderInformation);
    }

    return orderInformation;
  }

  @Action({ rawError: true })
  async loadOrderInformationsByPlushieIds(
    ids: string[]
  ): Promise<Dictionary<OrderInformation>> {
    const missing: string[] = [];
    const result: Dictionary<OrderInformation> = {};

    ids.forEach((id) => {
      if (!this.orderInformation[id]) {
        missing.push(id);
        return;
      }

      result[id] = this.orderInformation[id];
    });

    if (!missing.length) {
      return result;
    }

    const items = await DIContainer.OrderInformationRepository.getByIds(
      missing
    );

    Object.keys(items).forEach((id) => {
      const item = items[id];

      if (!item) {
        return;
      }

      this.updateOrderInformation(item);

      result[item.id] = item;
    });

    return result;
  }

  // ################################### PLUSHIES #########################################

  get getPlushieById(): (id: string) => Plushie | undefined {
    return (id: string) => this.plushie[id];
  }

  get getPlushiesByIds(): (ids: string[]) => Plushie[] {
    return (ids: string[]) => {
      const plushies: Plushie[] = [];

      ids.forEach((id) => {
        if (this.plushie[id]) {
          plushies.push(this.plushie[id]);
        }
      });

      return plushies;
    };
  }

  @Mutation
  updatePlushie(payload: Plushie): void {
    Vue.set(this.plushie, payload.id, payload);
  }

  @Action({ rawError: true })
  async loadPlushiesByIds(
    ids: string[]
  ): Promise<Dictionary<Plushie | undefined>> {
    const missing: string[] = [];
    const result: Dictionary<Plushie | undefined> = {};

    ids.forEach((id) => {
      if (!this.plushie[id]) {
        missing.push(id);
        return;
      }

      result[id] = this.plushie[id];
    });

    if (!missing.length) {
      return result;
    }

    const items = await DIContainer.PlushieRepository.getByIds(missing);

    Object.keys(items).forEach((id) => {
      const item = items[id];
      if (!item) {
        return;
      }

      this.updatePlushie(item);
    });

    return { ...result, ...items };
  }

  @Action({ rawError: true })
  async loadPlushieById({
    id,
    useCache = true,
  }: {
    id: string;
    useCache?: boolean;
  }): Promise<Plushie | undefined> {
    if (useCache && this.plushie[id]) {
      return this.plushie[id];
    }

    const plushie = await DIContainer.PlushieRepository.getById(id);

    if (!plushie) {
      return;
    }

    const cacheValueExists = this.plushie[id];

    this.updatePlushie(plushie);

    if (cacheValueExists) {
      void this.onPlushieUpdated(plushie.id);
    }

    return plushie;
  }

  @Action({ rawError: true })
  async loadPlushiesByStoreItemIds(
    ids: string[]
  ): Promise<Dictionary<Plushie>> {
    const items = await DIContainer.PlushieRepository.getByStoreItemIds(ids);

    Object.keys(items).forEach((storeItemId) => {
      const item = items[storeItemId];
      if (!item) {
        return;
      }

      this.updatePlushie(item);
    });

    return { ...items };
  }

  @Action({ rawError: true })
  async updatePlushieInformation(
    plushieInformationUpdate: PlushieInformationUpdate
  ): Promise<Plushie> {
    const item = await DIContainer.PlushieRepository.updatePlushieInformation(
      plushieInformationUpdate
    );

    this.updatePlushie(item);

    await this.onPlushieUpdated(item.id);

    return item;
  }

  @Action({ rawError: true })
  async createPlushieQuantityUpdateRequest({
    plushieId,
    quantity,
  }: {
    plushieId: string;
    quantity: number;
  }): Promise<Plushie> {
    const item = await DIContainer.PlushieRepository.createPlushieQuantityUpdateRequest(
      plushieId,
      quantity
    );

    this.updatePlushie(item);

    await this.onPlushieUpdated(item.id);

    return item;
  }

  @Action({ rawError: true })
  async setPlushie(plushie: Plushie): Promise<Plushie> {
    this.updatePlushie(plushie);

    await this.context.dispatch("onPlushieUpdated", plushie.id);

    return plushie;
  }

  @Action({ rawError: true })
  async refreshPlushie(plushieId: string): Promise<Plushie | undefined> {
    return this.loadPlushieById({ id: plushieId, useCache: false });
  }

  // ################################### PLUSHIE IMAGES #########################################

  get getPlushieImageById(): (id: string) => PlushieImage | undefined {
    return (id: string) => this.image[id];
  }

  get getPlushieImagesByPlushieId(): (plushieId: string) => PlushieImage[] {
    return (plushieId: string) => {
      const imagesIds = this.plushieImage[plushieId];

      if (!imagesIds) {
        return [];
      }

      const result: PlushieImage[] = [];
      const additionalArtworks: PlushieImage[] = [];
      const printArtworks: PlushieImage[] = [];

      imagesIds.forEach((id) => {
        const image = this.image[id];
        if (!image) {
          return;
        }

        if (image.type === PlushieImageType.MAIN) {
          result.unshift(image);
        } else if (image.type === PlushieImageType.PRINT) {
          printArtworks.push(image);
        } else {
          additionalArtworks.push(image);
        }
      });

      additionalArtworks.sort((a, b) => {
        return a.serialNumber > b.serialNumber ? 1 : -1;
      });

      printArtworks.sort((a, b) => {
        return a.serialNumber > b.serialNumber ? 1 : -1;
      });

      result.push(...additionalArtworks, ...printArtworks);

      return result;
    };
  }

  get getPlushieMainImageByPlushieId(): (
    plushieId: string
  ) => PlushieImage | undefined {
    return (plushieId: string) => {
      const imageId = this.plushieMainImage[plushieId];
      if (!imageId) {
        return undefined;
      }

      return this.image[imageId];
    };
  }

  @Mutation
  removePlushieImage(payload: PlushieImage): void {
    const plushieRelations = this.plushieImage[payload.plushie];
    let index = -1;

    if (plushieRelations) {
      index = plushieRelations.indexOf(payload.id);
    }

    if (index !== -1) {
      plushieRelations.splice(index, 1);
    }

    if (payload.type === PlushieImageType.MAIN) {
      delete this.plushieMainImage[payload.plushie];
    }

    delete this.image[payload.id];
  }

  @Mutation
  updatePlushieImage(payload: PlushieImage): void {
    Vue.set(this.image, payload.id, payload);

    if (
      this.plushieImage[payload.plushie] !== undefined &&
      !this.plushieImage[payload.plushie].includes(payload.id)
    ) {
      this.plushieImage[payload.plushie].push(payload.id);
    }

    if (payload.type === PlushieImageType.MAIN) {
      this.plushieMainImage[payload.plushie] = payload.id;
    }
  }

  @Mutation
  updatePlushieImages({
    plushieId,
    images,
  }: {
    plushieId: string;
    images: PlushieImage[];
  }): void {
    images.forEach((image) => {
      if (image.plushie !== plushieId) {
        throw new Error("All images should belong to the specified plushie!");
      }
    });

    const plushieImages: string[] = [];

    images.forEach((image) => {
      plushieImages.push(image.id);
      Vue.set(this.image, image.id, image);

      if (image.type === PlushieImageType.MAIN) {
        this.plushieMainImage[image.plushie] = image.id;
      }
    });

    Vue.set(this.plushieImage, plushieId, plushieImages);
  }

  @Action({ rawError: true })
  async createPlushieImage({
    id,
    plushie,
    storageItem,
  }: {
    id: string;
    plushie: string;
    storageItem: string;
  }): Promise<PlushieImage> {
    const item = await DIContainer.PlushieImageRepository.createArtwork(
      id,
      plushie,
      storageItem
    );

    this.updatePlushieImage(item);

    return item;
  }

  @Action({ rawError: true })
  async deletePlushieImage(image: PlushieImage): Promise<void> {
    await DIContainer.PlushieImageRepository.delete(image);

    this.removePlushieImage(image);

    return;
  }

  @Action({ rawError: true })
  async loadPlushieImagesByIds({
    ids,
    useCache = true,
  }: {
    ids: string[];
    useCache?: boolean;
  }): Promise<Dictionary<PlushieImage | undefined>> {
    const missing: string[] = [];
    const result: Dictionary<PlushieImage | undefined> = {};

    ids.forEach((id) => {
      if (useCache && this.image[id]) {
        result[id] = this.image[id];
        return;
      }

      missing.push(id);
    });

    if (!missing.length) {
      return result;
    }

    const items = await DIContainer.PlushieImageRepository.getByIds(missing);

    Object.keys(items).forEach((id) => {
      const item = items[id];
      if (!item) {
        return;
      }

      this.updatePlushieImage(item);
    });

    return { ...result, ...items };
  }

  @Action({ rawError: true })
  async loadPlushieImageByPlushieId({
    plushieId,
    useCache = true,
  }: {
    plushieId: string;
    useCache?: boolean;
  }): Promise<PlushieImage[]> {
    let images: PlushieImage[] = [];

    if (useCache && this.plushieImage[plushieId]) {
      const imagesIds = this.plushieImage[plushieId];

      imagesIds.forEach((id) => {
        images.push(this.image[id]);
      });

      return images;
    }

    images = await DIContainer.PlushieImageRepository.getByPlushieId(plushieId);

    this.updatePlushieImages({ plushieId, images });

    return images;
  }

  @Action({ rawError: true })
  async loadPlushieMainImageByPlushieIds(
    plushieIds: string[]
  ): Promise<Dictionary<PlushieImage>> {
    const missing: string[] = [];
    const result: Dictionary<PlushieImage> = {};

    plushieIds.forEach((id) => {
      if (!this.plushieMainImage[id]) {
        missing.push(id);
        return;
      }

      const imageId = this.plushieMainImage[id];
      result[id] = this.image[imageId];
    });

    if (!missing.length) {
      return result;
    }

    const items = await DIContainer.PlushieImageRepository.getMainByPlushieIds(
      missing
    );

    items.forEach((item) => {
      this.updatePlushieImage(item);
      result[item.plushie] = item;
    });

    return result;
  }

  @Action({ rawError: true })
  async setPlushieImageAsMain(image: PlushieImage): Promise<void> {
    const currentMainArtwork = this.plushieMainImage[image.plushie];

    if (currentMainArtwork == image.id) {
      return;
    }

    const images = await DIContainer.PlushieImageRepository.setAsMain(image);

    images.forEach((image) => {
      this.updatePlushieImage(image);
    });

    return;
  }

  // ################################### PLUSHIE ARBITRARY ATTRIBUTES #########################################

  get getArbitraryAttributesByPlushieId(): (
    plushieId: string
  ) => PlushieArbitraryAttribute[] {
    return (plushieId: string) => {
      const ids = this.plushieArbitraryAttribute[plushieId];

      if (!ids) {
        return [];
      }

      const result: PlushieArbitraryAttribute[] = [];

      ids.forEach((id) => {
        const arbitraryAttribute = this.arbitraryAttribute[id];

        if (!arbitraryAttribute) {
          return;
        }

        result.push(arbitraryAttribute);
      });

      result.sort((a, b) => ((a.name || -1) > (b.name || -1) ? 1 : -1));

      return result;
    };
  }

  @Mutation
  updatePlushieArbitraryAttributes({
    plushieId,
    arbitraryAttributes,
  }: {
    plushieId: string;
    arbitraryAttributes: PlushieArbitraryAttribute[];
  }): void {
    arbitraryAttributes.forEach((arbitraryAttribute) => {
      if (arbitraryAttribute.plushie !== plushieId) {
        throw new Error(
          "All Arbitrary Attributes should belong to the specified plushie!"
        );
      }
    });

    const plushieArbitraryAttributes: string[] = [];

    arbitraryAttributes.forEach((arbitraryAttribute) => {
      plushieArbitraryAttributes.push(arbitraryAttribute.id);

      Vue.set(
        this.arbitraryAttribute,
        arbitraryAttribute.id,
        arbitraryAttribute
      );
    });

    Vue.set(
      this.plushieArbitraryAttribute,
      plushieId,
      plushieArbitraryAttributes
    );
  }

  @Action({ rawError: true })
  async loadArbitraryAttributesByPlushieId({
    plushieId,
    useCache = true,
  }: {
    plushieId: string;
    useCache?: boolean;
  }): Promise<PlushieArbitraryAttribute[]> {
    let arbitraryAttributes: PlushieArbitraryAttribute[] = [];

    if (useCache && this.plushieArbitraryAttribute[plushieId]) {
      const ids = this.plushieArbitraryAttribute[plushieId];

      ids.forEach((id) => {
        arbitraryAttributes.push(this.arbitraryAttribute[id]);
      });

      return arbitraryAttributes;
    }

    arbitraryAttributes = await DIContainer.PlushieArbitraryAttributeRepository.getByPlushieId(
      plushieId
    );

    this.updatePlushieArbitraryAttributes({ plushieId, arbitraryAttributes });

    return arbitraryAttributes;
  }

  // ################################### PLUSHIE STATUSES #########################################

  get getPlushieStatusById(): (id: string) => PlushieStatus | undefined {
    return (id: string) => this.plushieStatus[id];
  }

  get plushieStatusList(): PlushieStatus[] {
    const list: PlushieStatus[] = [];

    Object.keys(this.plushieStatus).forEach((id) => {
      list.push(this.plushieStatus[id]);
    });

    list.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));

    return list;
  }

  get statusesDictionary(): Dictionary<DirectoryValue> {
    return { ...this.plushieStatus };
  }

  @Mutation
  updatePlushieStatus(payload: PlushieStatus): void {
    Vue.set(this.plushieStatus, payload.id, payload);
  }

  @Action({ rawError: true })
  async loadPlushieStatuses(): Promise<PlushieStatus[]> {
    let items: PlushieStatus[];

    if (Object.keys(this.plushieStatus).length === 0) {
      const collection = await DIContainer.PlushieStatusRepository.getList();
      items = collection.getItems();

      items.forEach((item) => {
        this.updatePlushieStatus(item);
      });
    }

    items = [];

    Object.keys(this.plushieStatus).forEach((key) => {
      items.push(this.plushieStatus[key]);
    });

    return items;
  }

  @Action({ rawError: true })
  async loadPlushieStatusById(id: string): Promise<PlushieStatus | undefined> {
    await this.loadPlushieStatuses();

    return this.getPlushieStatusById(id);
  }

  // ################################### PLUSHIE UPGRADES #########################################

  get getPlushieUpgradesByPlushieId(): (plushieId: string) => string[] {
    return (plushieId: string) => {
      if (this.plushieUpgrade[plushieId] == null) {
        return [];
      }

      return [...this.plushieUpgrade[plushieId]];
    };
  }

  @Mutation
  updatePlushieUpgrades({
    plushieId,
    upgrades,
  }: {
    plushieId: string;
    upgrades: string[];
  }): void {
    Vue.set(this.plushieUpgrade, plushieId, upgrades);
  }

  @Action({ rawError: true })
  async loadPlushieUpgradesByPlushieId({
    plushieId,
    useCache = true,
  }: {
    plushieId: string;
    useCache?: boolean;
  }): Promise<string[]> {
    if (useCache && this.plushieUpgrade[plushieId] != null) {
      return this.plushieUpgrade[plushieId];
    }

    const relations = await DIContainer.PlushieUpgradeRelationRepository.getByPlushieId(
      plushieId
    );

    const upgrades: string[] = [];

    relations.forEach((relation) => {
      upgrades.push(relation.upgrade);
    });

    this.updatePlushieUpgrades({ plushieId, upgrades });

    return upgrades;
  }

  @Action({ rawError: true })
  async loadPlushieUpgradesByPlushieIds({
    plushieIds,
    useCache = true,
  }: {
    plushieIds: string[];
    useCache?: boolean;
  }): Promise<Dictionary<string[]>> {
    const missing: string[] = [];
    const result: Dictionary<string[]> = {};

    plushieIds.forEach((id) => {
      if (useCache && this.plushieUpgrade[id]) {
        result[id] = this.plushieUpgrade[id];
        return;
      }

      missing.push(id);
    });

    if (!missing.length) {
      return result;
    }

    const relations = await DIContainer.PlushieUpgradeRelationRepository.getByPlushieIds(
      missing
    );

    const upgrades: Dictionary<string[]> = {};

    relations.forEach((relation) => {
      if (!upgrades[relation.plushie]) {
        upgrades[relation.plushie] = [];
      }

      upgrades[relation.plushie].push(relation.upgrade);
    });

    Object.keys(upgrades).forEach((plushieId) => {
      this.updatePlushieUpgrades({ plushieId, upgrades: upgrades[plushieId] });

      result[plushieId] = upgrades[plushieId];
    });

    return result;
  }

  // ################################### PRODUCTS #########################################

  get getProductById(): (id: string) => Product | undefined {
    return (id: string) => this.product[id];
  }

  get activeProductsList(): Product[] {
    const list: Product[] = [];

    Object.keys(this.product).forEach((id) => {
      if (this.product[id].isActive) {
        list.push(this.product[id]);
      }
    });

    list.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));

    return list;
  }

  get batchesProductsList(): Product[] {
    const list: Product[] = [];

    Object.values(this.product).forEach((product) => {
      if (!product.isBatch) {
        return;
      }

      list.push(product);
    });

    list.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));

    return list;
  }

  get productsList(): Product[] {
    const list: Product[] = [];

    Object.keys(this.product).forEach((id) => {
      list.push(this.product[id]);
    });

    list.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));

    return list;
  }

  get productsDictionary(): Dictionary<DirectoryValue> {
    return { ...this.product };
  }

  @Mutation
  updateProduct(payload: Product): void {
    Vue.set(this.product, payload.id, payload);
  }

  @Action({ rawError: true })
  async loadProducts(): Promise<Product[]> {
    let items: Product[];

    if (Object.keys(this.product).length === 0) {
      const collection = await DIContainer.ProductRepository.getList();
      items = collection.getItems();

      items.forEach((item) => {
        this.updateProduct(item);
      });
    }

    items = [];

    Object.keys(this.product).forEach((key) => {
      items.push(this.product[key]);
    });

    return items;
  }

  @Action({ rawError: true })
  async loadProductById(id: string): Promise<Product | undefined> {
    await this.loadProducts();

    return this.getProductById(id);
  }

  // ################################### STORES #########################################

  get getStoreById(): (id: string) => DirectoryValue | undefined {
    return (id: string) => this.sellingStore[id];
  }

  get storesDictionary(): Dictionary<DirectoryValue> {
    return { ...this.sellingStore };
  }

  get storesList(): DirectoryValue[] {
    const list: DirectoryValue[] = [];

    Object.keys(this.sellingStore).forEach((id) => {
      list.push(this.sellingStore[id]);
    });

    list.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));

    return list;
  }

  @Mutation
  updateStore(payload: DirectoryValue): void {
    Vue.set(this.sellingStore, payload.id, payload);
  }

  @Action({ rawError: true })
  async loadStores(): Promise<DirectoryValue[]> {
    let items: DirectoryValue[];

    if (Object.keys(this.sellingStore).length === 0) {
      const collection = await DIContainer.StoreRepository.getList();
      items = collection.getItems();

      items.forEach((item) => {
        this.updateStore(item);
      });
    }

    items = [];

    Object.keys(this.sellingStore).forEach((key) => {
      items.push(this.sellingStore[key]);
    });

    return items;
  }

  @Action({ rawError: true })
  async loadStoreById(id: string): Promise<DirectoryValue | undefined> {
    await this.loadStores();

    return this.getStoreById(id);
  }

  // ################################### UPGRADES #########################################

  get getUpgradeById(): (id: string) => Upgrade | undefined {
    return (id: string) => this.upgrade[id];
  }

  get getUpgradesByIds(): (ids: string[]) => Upgrade[] {
    return (ids: string[]) => {
      const result: Upgrade[] = [];

      ids.forEach((id) => {
        if (this.upgrade[id]) {
          result.push(this.upgrade[id]);
        }
      });

      return result;
    };
  }

  get upgradesList(): Upgrade[] {
    const list: Upgrade[] = [];

    Object.keys(this.upgrade).forEach((id) => {
      list.push(this.upgrade[id]);
    });

    list.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));

    return list;
  }

  get upgradeListForFactories(): Upgrade[] {
    return this.upgradesList.filter(({ displayToFactory }) => displayToFactory);
  }

  @Mutation
  updateUpgrade(payload: Upgrade): void {
    Vue.set(this.upgrade, payload.id, payload);
  }

  @Action({ rawError: true })
  async loadUpgrades(): Promise<Upgrade[]> {
    let items: Upgrade[];

    if (Object.keys(this.upgrade).length === 0) {
      const collection = await DIContainer.UpgradeRepository.getList();
      items = collection.getItems();

      items.forEach((item) => {
        this.updateUpgrade(item);
      });
    }

    items = [];

    Object.keys(this.upgrade).forEach((key) => {
      items.push(this.upgrade[key]);
    });

    return items;
  }

  @Action({ rawError: true })
  async loadUpgradeById(id: string): Promise<Upgrade | undefined> {
    await this.loadUpgrades();

    return this.getUpgradeById(id);
  }

  // ################################### Shipping Information #########################################
  get getShippingInformationByPlushieId(): (
    plushieId: string
  ) => ShippingInformation | undefined {
    return (plushieId: string) => {
      return this.shippingInformation[plushieId];
    };
  }

  @Mutation
  updateShippingInformation(shippingInformation: ShippingInformation): void {
    Vue.set(
      this.shippingInformation,
      shippingInformation.id,
      shippingInformation
    );
  }

  @Action({ rawError: true })
  async loadShippingInformationByPlushieId(
    plushieId: string
  ): Promise<ShippingInformation | undefined> {
    const result = await DIContainer.ShippingInformationRepository.getById(
      plushieId
    );

    if (!result) {
      return;
    }

    this.updateShippingInformation(result);

    return result;
  }

  @Action({ rawError: true })
  async saveShippingInformation(
    shippingInformation: ShippingInformation
  ): Promise<void> {
    const result = await DIContainer.ShippingInformationRepository.createShippingInformation(
      shippingInformation
    );

    if (!result) {
      return;
    }

    this.updateShippingInformation(result);
  }

  // ################################### TRACKING INFORMATION #########################################

  get getTrackingInformationById(): (
    id: string
  ) => TrackingInformation | undefined {
    return (id: string) => this.trackingInformation[id];
  }

  @Mutation
  updateTrackingInformation(trackingInformation: TrackingInformation): void {
    Vue.set(
      this.trackingInformation,
      trackingInformation.id,
      trackingInformation
    );
  }

  @Action({ rawError: true })
  async loadTrackingInformationById({
    id,
    useCache = true,
  }: {
    id: string;
    useCache?: boolean;
  }): Promise<TrackingInformation | undefined> {
    if (useCache && this.trackingInformation[id]) {
      return this.trackingInformation[id];
    }

    const response = await DIContainer.TrackingInformationRepository.getById(
      id
    );

    if (response) {
      this.updateTrackingInformation(response);
    }

    return response;
  }

  @Action({ rawError: true })
  async saveTrackingInformation(
    trackingInformation: TrackingInformation
  ): Promise<TrackingInformation> {
    const response = await DIContainer.TrackingInformationRepository.save(
      trackingInformation
    );

    this.updateTrackingInformation(response);

    return response;
  }

  // ################################### ATTACHMENTS #########################################

  get getAttachmentsByPlushieId(): (plushieId: string) => PlushieAttachment[] {
    return (plushieId: string) => {
      const attachments: PlushieAttachment[] = [];

      const attachmentIds: string[] = this.plushieAttachment[plushieId] || [];

      attachmentIds.forEach((id) => {
        attachments.push(this.attachment[id]);
      });

      return attachments;
    };
  }

  @Mutation
  updateAttachments({
    plushieId,
    attachments,
  }: {
    plushieId: string;
    attachments: PlushieAttachment[];
  }): void {
    const plushieAttachments: string[] = [];

    attachments.forEach((attachment) => {
      plushieAttachments.push(attachment.id);

      Vue.set(this.attachment, attachment.id, attachment);
    });

    Vue.set(this.plushieAttachment, plushieId, plushieAttachments);
  }

  @Mutation
  updateAttachmentType(attachmentType: PlushieAttachmentType): void {
    Vue.set(this.attachmentType, attachmentType.id, attachmentType);
  }

  @Action({ rawError: true })
  async loadAttachmentsByPlushieId({
    plushieId,
    useCache = true,
  }: {
    plushieId: string;
    useCache?: boolean;
  }): Promise<PlushieAttachment[]> {
    const cachedAttachments = this.getAttachmentsByPlushieId(plushieId);
    if (!!cachedAttachments.length && useCache) {
      return cachedAttachments;
    }

    const attachments = await DIContainer.PlushieAttachmentRepository.getByPlushieId(
      plushieId
    );

    this.updateAttachments({ plushieId, attachments });

    return attachments;
  }

  @Action({ rawError: true })
  async loadAttachmentTypesByIds({
    ids,
    useCache = true,
  }: {
    ids: string[];
    useCache?: boolean;
  }): Promise<Dictionary<PlushieAttachmentType>> {
    const missing: string[] = [];
    const result: Dictionary<PlushieAttachmentType> = {};

    ids.forEach((id) => {
      if (useCache && this.attachmentType[id]) {
        result[id] = this.attachmentType[id];
        return;
      }

      missing.push(id);
    });

    if (!missing.length) {
      return result;
    }

    const attachmentTypes = await DIContainer.PlushieAttachmentTypeRepository.getByIds(
      missing
    );

    Object.values(attachmentTypes).forEach((attachmentType) => {
      if (!attachmentType) {
        return;
      }

      result[attachmentType.id] = attachmentType;

      this.updateAttachmentType(attachmentType);
    });

    return result;
  }

  // ################################### EVENTS #########################################

  @Action({ rawError: true })
  async onPlushieUpdated(plushieId: string): Promise<void> {
    if (this.designInformation[plushieId]) {
      await this.loadDesignInformationByPlushieId({
        plushieId,
        useCache: false,
      });
    }
    return;
  }

  // ################################### DATA WIPING #########################################

  @Mutation
  resetState(): void {
    const state = (dataStore.state as any)[name];

    if (state) {
      Object.assign(state, getDefaultState());
    }
  }
}
