import Vue from "vue";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

import { ApiFilterValue } from "@/modules/api/api-filter-value.type";
import { QueryOrderParameter } from "@/modules/api/query-order-parameter";
import { DIContainer } from "@/app.container";
import { Dictionary } from "@/lib/Dictionary.type";
import { DirectoryValue } from "@/modules/api/directory-value.model";
import dataStore from "@/store";
import { ResourceCollection } from "@/modules/api/resource.collection";

import Factory from "../factory.model";
import FactoryAssignment from "../plushie-factory-asignment.model";
import UserFactoryRelation from "../user-factory-relation.model";
import ProductionTimeNorm from "../production-time-norm.model";
import ProductionTimeNormHistoryItem from "../production-time-norm-history-item.model";
import ProductAllocation from "../product-allocation.model";
import UpgradeAllocation from "../upgrade-allocation.model";
import FactoryInvoicingSettings from "../factory-invoicing-settings.model";
import { ProductAllocationSlotTypeValue } from "../product-allication-slot-type.value";

const name = "FactoryStore";

if ((dataStore.state as any)[name]) {
  dataStore.unregisterModule(name);
}

const getDefaultState = () => {
  return {
    factory: {},
    language: {},
    factoryAssignment: {},
    factoryProductionTimeNorms: {},
    factoryProductionTimeNormHistoryItems: {},
    historyType: {},
    plushie: {},
    productAllocation: {},
    productFactoriesRelation: {},
    productionTimeNorm: {},
    productionTimeNormHistoryItem: {},
    userFactoryRelation: {},
    userRelations: {},
    factoryRelations: {},
    allFactoriesLoaded: false,
    factoryInvoicingSettings: {},
  };
};

interface PlushieRelations {
  factoryAssignment: string;
}

@Module({ name, dynamic: true, store: dataStore })
export default class FactoryStore extends VuexModule {
  factory: Dictionary<Factory> = {};
  language: Dictionary<DirectoryValue> = {};
  factoryAssignment: Dictionary<FactoryAssignment> = {};
  factoryProductionTimeNorms: Dictionary<string[]> = {};
  factoryProductionTimeNormHistoryItems: Dictionary<string[]> = {};
  historyType: Dictionary<DirectoryValue> = {};
  plushie: Dictionary<PlushieRelations> = {};
  productAllocation: Dictionary<ProductAllocation> = {};
  productFactoriesRelation: Dictionary<string[]> = {};
  productionTimeNorm: Dictionary<ProductionTimeNorm> = {};
  productionTimeNormHistoryItem: Dictionary<ProductionTimeNormHistoryItem> = {};
  upgradeAllocation: Dictionary<UpgradeAllocation> = {};
  userFactoryRelation: Dictionary<UserFactoryRelation> = {};
  userRelations: Dictionary<string> = {};
  factoryRelations: Dictionary<string[]> = {};
  factoryInvoicingSettings: Dictionary<FactoryInvoicingSettings> = {};

  allFactoriesLoaded = false;

  // ################################### FACTORIES #########################################

  get factoriesList(): Factory[] {
    const list: Factory[] = [];

    Object.keys(this.factory).forEach((id) => {
      list.push(this.factory[id]);
    });

    list.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));

    return list;
  }

  get activeFactoriesList(): Factory[] {
    const list: Factory[] = [];

    Object.keys(this.factory).forEach((id) => {
      if (!this.factory[id].isActive) {
        return;
      }

      list.push(this.factory[id]);
    });

    list.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));

    return list;
  }

  get getFactoryById(): (id: string) => Factory | undefined {
    return (id: string) => this.factory[id];
  }

  @Mutation
  updateAllFactoriesLoaded(value: boolean): void {
    this.allFactoriesLoaded = value == true;
  }

  @Mutation
  updateFactory(payload: Factory): void {
    Vue.set(this.factory, payload.id, payload);
  }

  @Action({ rawError: true })
  async loadFactories(): Promise<Factory[]> {
    let items: Factory[];

    if (!this.allFactoriesLoaded) {
      const collection = await DIContainer.FactoryRepository.getList(1, 999);
      items = collection.getItems();

      items.forEach((item) => {
        this.updateFactory(item);
      });

      this.updateAllFactoriesLoaded(true);
    }

    items = [];

    Object.keys(this.factory).forEach((key) => {
      items.push(this.factory[key]);
    });

    return items;
  }

  @Action({ rawError: true })
  async loadFactoriesWithFilter({
    filter,
    order,
    useCache = true,
  }: {
    filter?: Dictionary<ApiFilterValue>;
    order?: QueryOrderParameter;
    useCache?: boolean;
  }): Promise<ResourceCollection<Factory>> {
    const collection = await DIContainer.FactoryRepository.getList(
      1,
      999,
      filter,
      order,
      useCache
    );

    collection.getItems().forEach((item) => {
      this.updateFactory(item);
    });

    return collection;
  }

  @Action({ rawError: true })
  async loadFactoryById({
    id,
    useCache = true,
  }: {
    id: string;
    useCache?: boolean;
  }): Promise<Factory | undefined> {
    if (useCache && this.factory[id] !== undefined) {
      return this.factory[id];
    }

    const factory = await DIContainer.FactoryRepository.getById(id, useCache);

    if (factory) {
      this.updateFactory(factory);
    }

    return factory;
  }

  @Action({ rawError: true })
  async saveFactory(factory: Factory): Promise<Factory> {
    const item = await DIContainer.FactoryRepository.save(factory);

    this.updateFactory(item);

    return item;
  }

  // ################################### HISTORY TYPES #########################################
  get getHistoryTypeById(): (id: string) => DirectoryValue | undefined {
    return (id: string) => this.historyType[id];
  }

  @Mutation
  updateHistoryType(payload: DirectoryValue): void {
    Vue.set(this.historyType, payload.id, payload);
  }

  @Action({ rawError: true })
  async loadHistoryTypes(): Promise<DirectoryValue[]> {
    let items: DirectoryValue[];

    if (Object.keys(this.historyType).length === 0) {
      const collection = await DIContainer.HistoryTypeRepository.getList();
      items = collection.getItems();

      items.forEach((item) => {
        this.updateHistoryType(item);
      });
    }

    items = [];

    Object.keys(this.historyType).forEach((key) => {
      items.push(this.historyType[key]);
    });

    return items;
  }

  // ################################### LANGUAGES #########################################

  get languages(): DirectoryValue[] {
    const list: DirectoryValue[] = [];

    Object.keys(this.language).forEach((id) => {
      list.push(this.language[id]);
    });

    list.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));

    return list;
  }

  get languagesDictionary(): Dictionary<DirectoryValue> {
    return { ...this.language };
  }

  get getLanguageById(): (id: string) => DirectoryValue | undefined {
    return (id: string) => this.language[id];
  }

  @Mutation
  updateLanguage(payload: DirectoryValue): void {
    Vue.set(this.language, payload.id, payload);
  }

  @Action({ rawError: true })
  async loadLanguages(): Promise<DirectoryValue[]> {
    let items: DirectoryValue[];

    if (Object.keys(this.language).length === 0) {
      const collection = await DIContainer.LanguageRepository.getList();
      items = collection.getItems();

      items.forEach((item) => {
        this.updateLanguage(item);
      });
    }

    items = [];

    Object.keys(this.language).forEach((key) => {
      items.push(this.language[key]);
    });

    return items;
  }

  @Action({ rawError: true })
  async loadLanguageById(id: string): Promise<DirectoryValue | undefined> {
    await this.loadLanguages();

    return this.getLanguageById(id);
  }

  // ################################### FACTORY ASSIGNMENTS #########################################

  get getFactoryAssignmentByPlushieId(): (
    plushieId: string
  ) => FactoryAssignment | undefined {
    return (plushieId: string) => {
      if (this.plushie[plushieId] == null) {
        return undefined;
      }

      const id = this.plushie[plushieId].factoryAssignment;

      if (id == null) {
        return undefined;
      }

      return this.factoryAssignment[id];
    };
  }

  @Mutation
  updateFactoryAssignment(payload: FactoryAssignment): void {
    Vue.set(this.factoryAssignment, payload.id, payload);
    this.plushie[payload.plushie] = { factoryAssignment: payload.id };
  }

  @Action({ rawError: true })
  async loadFactoryAssignmentByPlushieId({
    plushieId,
    useCache = true,
  }: {
    plushieId: string;
    useCache?: boolean;
  }): Promise<FactoryAssignment | undefined> {
    let assignmentId;

    if (useCache && this.plushie[plushieId] != null) {
      assignmentId = this.plushie[plushieId].factoryAssignment;
    }

    if (assignmentId != null && this.factoryAssignment[assignmentId] != null) {
      return this.factoryAssignment[assignmentId];
    }

    const factoryAssignment = await DIContainer.PlushieFactoryAssignmentRepository.getByPlushieId(
      plushieId
    );

    if (factoryAssignment) {
      this.updateFactoryAssignment(factoryAssignment);
    }

    return factoryAssignment;
  }

  @Action({ rawError: true })
  async loadFactoryAssignmentsByPlushieIds({
    plushieIds,
    useCache = true,
  }: {
    plushieIds: string[];
    useCache?: boolean;
  }): Promise<Dictionary<FactoryAssignment>> {
    const missing: string[] = [];
    const result: Dictionary<FactoryAssignment> = {};

    plushieIds.forEach((id) => {
      if (useCache && this.plushie[id] != null) {
        const assignmentId = this.plushie[id].factoryAssignment;

        if (!assignmentId || !this.factoryAssignment[assignmentId]) {
          missing.push(id);
          return;
        }

        result[id] = this.factoryAssignment[assignmentId];
        return;
      }

      missing.push(id);
    });

    if (!missing.length) {
      return result;
    }

    const items = await DIContainer.PlushieFactoryAssignmentRepository.getByPlushieIds(
      missing
    );

    Object.keys(items).forEach((itemId) => {
      const assignment = items[itemId];

      if (assignment === undefined) {
        return;
      }

      this.updateFactoryAssignment(assignment);

      result[assignment.plushie] = assignment;
    });

    return result;
  }

  // ################################### PRODUCT ALLOCATIONS #########################################
  get getFactoriesIdsByProductId(): (productId: string) => string[] {
    return (productId: string) => {
      return this.productFactoriesRelation[productId]
        ? Array.from(this.productFactoriesRelation[productId])
        : [];
    };
  }

  get getProductAllocationsBySlotType(): (
    slotType: ProductAllocationSlotTypeValue
  ) => ProductAllocation[] {
    return (slotType: ProductAllocationSlotTypeValue) =>
      Object.values(this.productAllocation).filter(
        (item) => item.slotType === slotType
      );
  }

  @Mutation
  updateProductAllocation(payload: ProductAllocation): void {
    const existingItem = this.productAllocation[payload.id];

    Vue.set(this.productAllocation, payload.id, payload);

    if (!this.productFactoriesRelation[payload.product]) {
      Vue.set(this.productFactoriesRelation, payload.product, []);
    }

    let productFactories = this.productFactoriesRelation[payload.product];

    if (
      payload.distributionPercent > 0 &&
      !productFactories.includes(payload.factory)
    ) {
      productFactories.push(payload.factory);
    }

    if (!existingItem) {
      return;
    }

    if (
      existingItem.product === payload.product &&
      existingItem.factory === payload.factory &&
      payload.distributionPercent > 0
    ) {
      return;
    }

    productFactories = this.productFactoriesRelation[existingItem.product];

    const index = productFactories?.indexOf(existingItem.factory);
    if (index === undefined || index === -1) {
      return;
    }

    productFactories.splice(index, 1);
  }

  @Action({ rawError: true })
  async loadProductAllocations(useCache = true): Promise<ProductAllocation[]> {
    let items: ProductAllocation[] = [];

    if (useCache && Object.keys(this.productAllocation).length > 0) {
      Object.keys(this.productAllocation).forEach((key) => {
        items.push(this.productAllocation[key]);
      });

      return items;
    }

    const collection = await DIContainer.ProductAllocationRepository.getList(
      1,
      9999
    );

    items = collection.getItems();

    items.forEach((item) => {
      this.updateProductAllocation(item);
    });

    return items;
  }

  @Action({ rawError: true })
  async saveProductAllocations(
    productAllocations: ProductAllocation[]
  ): Promise<ProductAllocation[]> {
    const collection = await DIContainer.ProductAllocationRepository.saveItems(
      productAllocations
    );

    const items = collection.getItems();

    items.forEach((item) => {
      this.updateProductAllocation(item);
    });

    return items;
  }

  // ################################### PRODUCTION TIME NORMS #########################################
  get getProductionTimeNormById(): (
    id: string
  ) => ProductionTimeNorm | undefined {
    return (id: string) => this.productionTimeNorm[id];
  }

  get getProductionTimeNormsByFactoryId(): (
    factoryId: string
  ) => ProductionTimeNorm[] {
    return (factoryId: string) => {
      if (this.factoryProductionTimeNorms[factoryId] == null) {
        return [];
      }

      const ids = this.factoryProductionTimeNorms[factoryId];

      const result: ProductionTimeNorm[] = [];

      ids.forEach((id) => {
        result.push(this.productionTimeNorm[id]);
      });

      return result;
    };
  }

  @Mutation
  updateFactoryProductionTimeNorms({
    factoryId,
    productionTimeNorms,
  }: {
    factoryId: string;
    productionTimeNorms: ProductionTimeNorm[];
  }): void {
    productionTimeNorms.forEach((timeNorm) => {
      if (timeNorm.factory !== factoryId) {
        throw new Error(
          "All production time norms should belong to the specified factory!"
        );
      }
    });

    const productionTimeNormIds: string[] = [];

    productionTimeNorms.forEach((timeNorm) => {
      productionTimeNormIds.push(timeNorm.id);
      Vue.set(this.productionTimeNorm, timeNorm.id, timeNorm);
    });

    Vue.set(this.factoryProductionTimeNorms, factoryId, productionTimeNormIds);
  }

  @Action({ rawError: true })
  async loadProductionTimeNormsByFactoryId({
    factoryId,
    useCache = true,
  }: {
    factoryId: string;
    useCache?: boolean;
  }): Promise<ProductionTimeNorm[]> {
    if (useCache && this.factoryProductionTimeNorms[factoryId]) {
      const ids = this.factoryProductionTimeNorms[factoryId];

      const result: ProductionTimeNorm[] = [];

      ids.forEach((id) => {
        result.push(this.productionTimeNorm[id]);
      });

      return result;
    }

    const collection = await DIContainer.ProductionTimeNormRepository.getByFactoryId(
      factoryId
    );

    const items = collection.getItems();

    this.updateFactoryProductionTimeNorms({
      factoryId,
      productionTimeNorms: items,
    });

    return items;
  }

  @Action({ rawError: true })
  async saveProductionTimeNormsForFactory({
    factoryId,
    values,
  }: {
    factoryId: string;
    values: ProductionTimeNorm[];
  }): Promise<ProductionTimeNorm[]> {
    const items = await DIContainer.ProductionTimeNormRepository.updateProductionTimeNormsForFactory(
      factoryId,
      values
    );

    this.updateFactoryProductionTimeNorms({
      factoryId,
      productionTimeNorms: items,
    });

    return items;
  }

  // ################################### PRODUCTION TIME NORM HISTORY ITEMS #########################################
  get getProductionTimeNormHistoryItemById(): (
    id: string
  ) => ProductionTimeNormHistoryItem | undefined {
    return (id: string) => this.productionTimeNormHistoryItem[id];
  }

  get getProductionTimeNormItemsByFactoryId(): (
    factoryId: string
  ) => ProductionTimeNormHistoryItem[] {
    return (factoryId: string) => {
      if (this.factoryProductionTimeNormHistoryItems[factoryId] == null) {
        return [];
      }

      const ids = this.factoryProductionTimeNormHistoryItems[factoryId];

      const result: ProductionTimeNormHistoryItem[] = [];

      ids.forEach((id) => {
        result.push(this.productionTimeNormHistoryItem[id]);
      });

      return result;
    };
  }

  @Mutation
  updateFactoryProductionTimeNormHistoryItems({
    factoryId,
    productionTimeNormHistoryItems,
  }: {
    factoryId: string;
    productionTimeNormHistoryItems: ProductionTimeNormHistoryItem[];
  }): void {
    productionTimeNormHistoryItems.forEach((historyItem) => {
      if (historyItem.factory !== factoryId) {
        throw new Error(
          "All production time norm history items should belong to the specified factory!"
        );
      }
    });

    const productionTimeNormHistoryItemIds: string[] = [];

    productionTimeNormHistoryItems.forEach((historyItem) => {
      productionTimeNormHistoryItemIds.push(historyItem.id);
      Vue.set(this.productionTimeNormHistoryItem, historyItem.id, historyItem);
    });

    Vue.set(
      this.factoryProductionTimeNormHistoryItems,
      factoryId,
      productionTimeNormHistoryItemIds
    );
  }

  @Action({ rawError: true })
  async loadProductionTimeNormHistoryItemsByFactoryId({
    factoryId,
    useCache = true,
  }: {
    factoryId: string;
    useCache?: boolean;
  }): Promise<ProductionTimeNormHistoryItem[]> {
    if (useCache && this.factoryProductionTimeNormHistoryItems[factoryId]) {
      const ids = this.factoryProductionTimeNormHistoryItems[factoryId];

      const result: ProductionTimeNormHistoryItem[] = [];

      ids.forEach((id) => {
        result.push(this.productionTimeNormHistoryItem[id]);
      });

      return result;
    }

    const collection = await DIContainer.ProductionTimeNormHistoryItemRepository.getByFactoryId(
      factoryId
    );

    const items = collection.getItems();

    this.updateFactoryProductionTimeNormHistoryItems({
      factoryId,
      productionTimeNormHistoryItems: items,
    });

    return items;
  }

  // ################################### UPGRADE ALLOCATION #########################################
  @Mutation
  updateUpgradeAllocation(payload: UpgradeAllocation): void {
    Vue.set(this.upgradeAllocation, payload.id, payload);
  }

  @Action({ rawError: true })
  async loadUpgradeAllocations(useCache = true): Promise<UpgradeAllocation[]> {
    let items: UpgradeAllocation[] = [];

    if (useCache && Object.keys(this.upgradeAllocation).length > 0) {
      Object.keys(this.upgradeAllocation).forEach((key) => {
        items.push(this.upgradeAllocation[key]);
      });

      return items;
    }

    const collection = await DIContainer.UpgradeAllocationRepository.getList(
      1,
      9999
    );

    items = collection.getItems();

    items.forEach((item) => {
      this.updateUpgradeAllocation(item);
    });

    return items;
  }

  @Action({ rawError: true })
  async saveUpgradeAllocations(
    upgradeAllocations: UpgradeAllocation[]
  ): Promise<UpgradeAllocation[]> {
    const collection = await DIContainer.UpgradeAllocationRepository.saveItems(
      upgradeAllocations
    );

    const items = collection.getItems();

    items.forEach((item) => {
      this.updateUpgradeAllocation(item);
    });

    return items;
  }

  // ################################### USER FACTORY RELATIONS #########################################
  get getUserFactoryRelationByUserId(): (
    userId: string
  ) => UserFactoryRelation | undefined {
    return (userId: string) => {
      const relationId = this.userRelations[userId];

      return this.userFactoryRelation[relationId];
    };
  }

  get getUserFactoryRelationsByFactoryId(): (
    factoryId: string
  ) => UserFactoryRelation[] {
    return (factoryId: string) => {
      const relationIds = this.factoryRelations[factoryId] || [];

      return relationIds.map(
        (relationId) => this.userFactoryRelation[relationId]
      );
    };
  }

  @Mutation
  clearFactoryRelationsForFactory(factoryId: string): void {
    Vue.set(this.factoryRelations, factoryId, []);
  }

  @Mutation
  updateUserFactoryRelation(payload: UserFactoryRelation): void {
    Vue.set(this.userFactoryRelation, payload.id, payload);
    Vue.set(this.userRelations, payload.user, payload.id);

    if (!this.factoryRelations[payload.factory]) {
      Vue.set(this.factoryRelations, payload.factory, []);
    }

    if (this.factoryRelations[payload.factory].includes(payload.id)) {
      return;
    }

    this.factoryRelations[payload.factory].push(payload.id);
  }

  @Mutation
  deleteUserFactoryRelationFromStore(relation: UserFactoryRelation): void {
    Vue.delete(this.userFactoryRelation, relation.id);
    Vue.delete(this.userRelations, relation.user);

    if (!this.factoryRelations[relation.factory]) {
      return;
    }

    const index = this.factoryRelations[relation.factory].findIndex(
      (relationId) => relation.id === relationId
    );

    if (index < 0) {
      return;
    }

    this.factoryRelations[relation.factory].splice(index, 1);
  }

  @Action({ rawError: true })
  async loadUserFactoryRelationByUserId({
    userId,
    useCache = true,
  }: {
    userId: string;
    useCache?: boolean;
  }): Promise<UserFactoryRelation | undefined> {
    if (useCache && this.userRelations[userId]) {
      return this.getUserFactoryRelationByUserId(userId);
    }

    const item = await DIContainer.UserFactoryRelationRepository.getByUserId(
      userId
    );

    if (!item) {
      return;
    }

    this.updateUserFactoryRelation(item);

    return item;
  }

  @Action({ rawError: true })
  async loadUserFactoryRelationByUserIds({
    userIds,
    useCache = true,
  }: {
    userIds: string[];
    useCache?: boolean;
  }): Promise<Dictionary<UserFactoryRelation>> {
    const missing: string[] = [];
    const result: Dictionary<UserFactoryRelation> = {};

    userIds.forEach((id) => {
      if (useCache && this.userRelations[id]) {
        const relation = this.getUserFactoryRelationByUserId(id);
        if (relation) {
          result[id] = relation;
          return;
        }
      }

      missing.push(id);
    });

    if (!missing.length) {
      return result;
    }

    const items = await DIContainer.UserFactoryRelationRepository.getByUserIds(
      missing
    );

    items.forEach((item) => {
      this.updateUserFactoryRelation(item);
      result[item.user] = item;
    });

    return result;
  }

  @Action({ rawError: true })
  async loadUserFactoryRelationsByFactoryId(
    factoryId: string
  ): Promise<UserFactoryRelation[]> {
    const items = await DIContainer.UserFactoryRelationRepository.getByFactoryId(
      factoryId
    );

    this.clearFactoryRelationsForFactory(factoryId);

    items.forEach((item) => {
      this.updateUserFactoryRelation(item);
    });

    return items;
  }

  @Action({ rawError: true })
  async saveUserFactoryRelation(
    relation: UserFactoryRelation
  ): Promise<UserFactoryRelation> {
    await DIContainer.UserFactoryRelationRepository.save(relation);

    this.updateUserFactoryRelation(relation);

    return relation;
  }

  @Action({ rawError: true })
  async deleteUserFactoryRelation(
    relation: UserFactoryRelation
  ): Promise<void> {
    await DIContainer.UserFactoryRelationRepository.deleteById(relation.id);
    this.deleteUserFactoryRelationFromStore(relation);
  }

  // ################################### FACTORY INVOICING SETTINGS #########################################

  @Mutation
  updateFactoryInvoicingSettings(
    factoryInvoicingSettings: FactoryInvoicingSettings
  ): void {
    Vue.set(
      this.factoryInvoicingSettings,
      factoryInvoicingSettings.id,
      factoryInvoicingSettings
    );
  }

  @Action({ rawError: true })
  async saveFactoryInvoicingSettings(
    factoryInvoicingSettings: FactoryInvoicingSettings
  ): Promise<void> {
    await DIContainer.FactoryInvoicingSettingsRepository.save(
      factoryInvoicingSettings
    );

    this.updateFactoryInvoicingSettings(factoryInvoicingSettings);
  }

  @Action({ rawError: true })
  async fetchFactoryInvoicingSettingsById({
    factoryId,
    useCache = true,
  }: {
    factoryId: string;
    useCache?: boolean;
  }): Promise<FactoryInvoicingSettings | undefined> {
    let factoryInvoicingSettings: FactoryInvoicingSettings | undefined = this
      .factoryInvoicingSettings[factoryId];

    if (useCache && factoryInvoicingSettings) {
      return factoryInvoicingSettings;
    }

    factoryInvoicingSettings = await DIContainer.FactoryInvoicingSettingsRepository.fetchByFactoryId(
      factoryId
    );

    if (!factoryInvoicingSettings) {
      return;
    }

    this.updateFactoryInvoicingSettings(factoryInvoicingSettings);
  }

  // ################################### EVENTS #########################################

  @Action({ rawError: true })
  async onPlushieUpdated(plushieId: string): Promise<void> {
    if (!this.plushie[plushieId]) {
      return;
    }

    if (!this.plushie[plushieId].factoryAssignment) {
      return;
    }

    const assignment = await this.loadFactoryAssignmentByPlushieId({
      plushieId,
      useCache: false,
    });

    if (!assignment) {
      return;
    }

    if (!this.factory[assignment.factory]) {
      await this.loadFactoryById({ id: assignment.factory });
    }
  }

  // ################################### DATA WIPING #########################################

  @Mutation
  resetState(): void {
    const state = (dataStore.state as any)[name];

    if (state) {
      Object.assign(state, getDefaultState());
    }
  }
}
