import { getModule } from "vuex-module-decorators";
import { saveAs } from "file-saver";

import dataStore from "@/store";

import FileStorageStore from "./store";
import StorageItemReferenceInterface from "./storage-item-reference.interface";
import MessageAttachmentHandlerService from "./message-attachment-handler.service";

export default class MessageAttachmentDownloaderService {
  private fAttachmentHandlerService: MessageAttachmentHandlerService;

  private fFileStorageDataStore: FileStorageStore;

  public constructor(
    attachmentHandlerService: MessageAttachmentHandlerService
  ) {
    this.fAttachmentHandlerService = attachmentHandlerService;
    this.fFileStorageDataStore = getModule(FileStorageStore, dataStore);
  }

  public async download(
    document: StorageItemReferenceInterface,
    fileName: string
  ): Promise<void> {
    const storageItem = this.fFileStorageDataStore.getItemById(
      document.storageItem
    );

    if (!storageItem) {
      return;
    }

    const originalUrl = this.fAttachmentHandlerService.getOriginalAttachmentUrl(
      storageItem.key
    );

    const response = await fetch(originalUrl);

    if (!response.ok) {
      throw new Error("Fail to download the attachment");
    }

    const blobBody = await response.blob();

    saveAs(blobBody, fileName);
  }
}
