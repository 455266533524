import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";

import TrackingInformation from "./tracking-information.model";

export default class TrackingInformationRepository extends BasicRepository<TrackingInformation> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<TrackingInformation>,
    responseParser: ApiCollectionResponseParserService<TrackingInformation>
  ) {
    super("tracking_informations", api, objectBuilder, responseParser);
  }

  protected prepareData(item: TrackingInformation): Record<string, string> {
    const data: Record<string, string> = {
      id: item.id,
      trackingNumber: item.trackingNumber,
    };

    return data;
  }
}
