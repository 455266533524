import { getModule } from "vuex-module-decorators";
import { saveAs } from "file-saver";

import dataStore from "@/store";

import FileStorageStore from "./store";
import StorageItemReferenceInterface from "./storage-item-reference.interface";
import ImageHandlerService from "./image-handler.service";

export default class ImageDownloaderService {
  private fImageHandlerService: ImageHandlerService;

  private fFileStorageDataStore: FileStorageStore;

  public constructor(imageHandlerService: ImageHandlerService) {
    this.fImageHandlerService = imageHandlerService;
    this.fFileStorageDataStore = getModule(FileStorageStore, dataStore);
  }

  public async download(
    image: StorageItemReferenceInterface,
    fileName: string
  ): Promise<void> {
    const storageItem = this.fFileStorageDataStore.getItemById(
      image.storageItem
    );

    if (!storageItem) {
      return;
    }

    const originalUrl = this.fImageHandlerService.getOriginalImageUrl(
      storageItem.url
    );

    const response = await fetch(originalUrl);

    if (!response.ok) {
      throw new Error("Fail to download the image");
    }

    const blobBody = await response.blob();

    saveAs(blobBody, fileName);
  }
}
