



































































import { Component, Prop, Vue, Watch, Inject } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import Raphael from "raphael";
import { create } from "vue-modal-dialogs";

import MoonLoader from "vue-spinner/src/MoonLoader.vue";

import ActionButton from "@/lib/components/ActionButton.vue";
import MarkedUpImagesGalleryFullscreenModal from "@/modules/photo-markup/components/MarkedUpImagesGalleryFullscreenModal.vue";

import { Dictionary } from "@/lib/Dictionary.type";
import dataStore from "@/store";
import FileStorageStore from "@/modules/file-storage/store";
import ImageDownloaderService from "@/modules/file-storage/image-downloader.service";
import ImageHandlerService from "@/modules/file-storage/image-handler.service";
import preloadImage from "@/lib/preload-image.function";
import PhotoMarkupStore from "@/modules/photo-markup/store";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import { Resource } from "@/modules/account/resource";
import ImagesGalleryFullscreenModalProp from "@/lib/images-gallery-fullscreen-modal-prop";
import VideoHandlerService from "@/modules/file-storage/video-handler.service";
import VideoDownloaderService from "@/modules/file-storage/video-downloader.service";
import { PlushieStatusValue } from "@/modules/plushie/plushie-status.value";
import PlushieStore from "@/modules/plushie/store";
import { EntityType } from "@/modules/photo-markup/entity-type";
import PlushieStorageItemNameResolverService from "@/modules/plushie/plushie-storage-item-name-resolver.service";
import Plushie from "@/modules/plushie/plushie.model";

import QualityInspectionStore from "../store";
import QaAsset from "../qa-asset.model";
import isAssetVideo from "../is-asset-video.function";
import QaAssetType from "../qa-asset-type";

@Component({
  components: {
    ActionButton,
    MoonLoader,
  },
})
export default class FinishedProductPhotosList extends Vue {
  @Prop({ required: true })
  public readonly plushieId!: string;

  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  @Inject("ImageDownloaderService")
  private fImageDownloaderService!: ImageDownloaderService;

  @Inject("ImageHandlerService")
  private fImageHandlerService!: ImageHandlerService;

  @Inject("PlushieStorageItemNameResolverService")
  private fPlushieStorageItemNameResolverService!: PlushieStorageItemNameResolverService;

  @Inject("VideoDownloaderService")
  private fVideoDownloaderService!: VideoDownloaderService;

  @Inject("VideoHandlerService")
  private fVideoHandlerService!: VideoHandlerService;

  @Inject("window")
  private fWindow!: Window;

  private fIsLoading = true;

  private fFileStorageDataStore: FileStorageStore;
  private fPhotoMarkupDataStore: PhotoMarkupStore;
  private fQualityInspectionDataStore: QualityInspectionStore;
  private fPlushieDataStore: PlushieStore;

  private fDownloadingQaAssets: Dictionary<boolean> = {};

  get qaAssets(): QaAsset[] {
    return this.fQualityInspectionDataStore.getQaAssetsByPlushieId(
      this.plushieId
    );
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get isTouchDevice(): boolean {
    return this.fWindow.matchMedia("(pointer: coarse)").matches;
  }

  get isMarkupAvailable(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    if (!user.hasPermissionForResource(Resource.PHOTO_MARKUPS_MANAGE)) {
      return false;
    }

    if (!this.plushie) {
      return false;
    }

    return [
      PlushieStatusValue.REVIEW,
      PlushieStatusValue.READY_FOR_PRODUCTION,
      PlushieStatusValue.IN_DESIGN,
      PlushieStatusValue.IN_PRODUCTION,
      PlushieStatusValue.QUALITY_INSPECTION,
      PlushieStatusValue.CUSTOMER_PREVIEW,
      PlushieStatusValue.REWORK,
      PlushieStatusValue.IN_BULK_DESIGN,
      PlushieStatusValue.IN_BULK_PRODUCTION,
      PlushieStatusValue.PPS_INSPECTION,
      PlushieStatusValue.PPS_PREVIEW,
      PlushieStatusValue.BULK_INSPECTION,
      PlushieStatusValue.BULK_PREVIEW,
    ].includes(this.plushie.status);
  }

  get plushie(): Plushie | undefined {
    return this.fPlushieDataStore.getPlushieById(this.plushieId);
  }

  public constructor() {
    super();

    this.fFileStorageDataStore = getModule(FileStorageStore, dataStore);
    this.fPhotoMarkupDataStore = getModule(PhotoMarkupStore, dataStore);
    this.fPlushieDataStore = getModule(PlushieStore, dataStore);

    this.fQualityInspectionDataStore = getModule(
      QualityInspectionStore,
      dataStore
    );
  }

  public addMarkupToPreviews(): void {
    const imageWrappers = this.getImageWrappers();
    const imageElements = this.getImages();

    if (!imageWrappers || !imageElements) {
      return;
    }

    for (let i = 0; i < this.qaAssets.length; i++) {
      const markup = this.fPhotoMarkupDataStore.getMarkupByImageId(
        this.qaAssets[i].id
      );

      if (!markup) {
        continue;
      }

      const imageElement = imageElements[i];
      const imageWrapper = imageWrappers[i];

      if (!imageElement || !imageWrapper) {
        continue;
      }

      const paper = Raphael(
        imageWrapper,
        imageElement.offsetWidth,
        imageElement.offsetHeight
      );

      paper.setViewBox(0, 0, markup.markup.width, markup.markup.height, true);

      const sketchpad = Raphael.sketchpad(paper, {
        editing: false,
        svgClass: "_sketchpad-svg",
      });

      sketchpad.json(markup.markup.strokes);
    }
  }

  public doesHaveMarkup(qaAsset: QaAsset): boolean {
    return (
      this.fPhotoMarkupDataStore.getMarkupByImageId(qaAsset.id) !== undefined
    );
  }

  public async downloadQaAsset(qaAsset: QaAsset, index: number): Promise<void> {
    if (this.isQaAssetDownloading(qaAsset)) {
      return;
    }

    Vue.set(this.fDownloadingQaAssets, qaAsset.id, true);

    const storageItem = this.fFileStorageDataStore.getItemById(
      qaAsset.storageItem
    );

    if (!storageItem) {
      throw new Error("Storage item data is not loaded");
    }

    if (!this.plushie) {
      throw new Error("Plushie data is not loaded");
    }

    const plushieStorageItemDownloadName = this.fPlushieStorageItemNameResolverService.resolve(
      storageItem,
      this.plushie,
      index
    );

    try {
      if (isAssetVideo(qaAsset)) {
        await this.fVideoDownloaderService.download(
          qaAsset,
          plushieStorageItemDownloadName
        );
      } else {
        await this.fImageDownloaderService.download(
          qaAsset,
          plushieStorageItemDownloadName
        );
      }
    } finally {
      Vue.delete(this.fDownloadingQaAssets, qaAsset.id);
    }
  }

  public getMarkupQuery(qaAsset: QaAsset): Dictionary<string> | undefined {
    return {
      plushieId: this.plushieId,
      imageId: qaAsset.id,
      entityTypeId: EntityType.QA_PHOTO,
      returnUrl: this.$router.currentRoute.fullPath,
    };
  }

  public isVideoAsset(qaAsset: QaAsset): boolean {
    return qaAsset.type === QaAssetType.VIDEO;
  }

  public getFormattedCreationTime(qaAsset: QaAsset): string {
    return qaAsset.createdAt.toLocaleString();
  }

  public getFullscreenThumbnail(qaAsset: QaAsset): string {
    return this.getThumbnail(qaAsset, 1920);
  }

  public getImageLegend(index: number): string {
    const currentIndex = index + 1;

    const imagesQty = this.qaAssets.length;

    return `${currentIndex}/${imagesQty}`;
  }

  public getThumbnail(
    qaAsset: QaAsset,
    size: number,
    shouldCrop = false
  ): string {
    const storageItemId = isAssetVideo(qaAsset)
      ? qaAsset.thumbnailStorageItem
      : qaAsset.storageItem;

    const storageItem = this.fFileStorageDataStore.getItemById(storageItemId);

    const url = storageItem
      ? storageItem.timestampedUrl
      : this.fFileStorageDataStore.placeholderUrl;

    return this.fImageHandlerService.getThumbnailUrl(
      url,
      size,
      size,
      shouldCrop
    );
  }

  public launchGalleria(index: number): void {
    const slidesData = this.qaAssets.map((qaAsset) => {
      if (isAssetVideo(qaAsset)) {
        const storageItem = this.fFileStorageDataStore.getItemById(
          qaAsset.storageItem
        );

        return {
          href: this.fVideoHandlerService.getOriginalVideoUrl(
            storageItem
              ? storageItem.timestampedUrl
              : this.fFileStorageDataStore.placeholderUrl
          ),
          poster: this.getFullscreenThumbnail(qaAsset),
          thumbnail: this.getThumbnail(qaAsset, 200, true),
          type: "video/mp4",
        };
      } else {
        const markup = this.fPhotoMarkupDataStore.getMarkupByImageId(
          qaAsset.id
        );

        return {
          href: this.getFullscreenThumbnail(qaAsset),
          thumbnail: this.getThumbnail(qaAsset, 200, true),
          markup: markup ? markup.markup : undefined,
          type: "markedUpImage",
        };
      }
    });

    const modalFunction = create(MarkedUpImagesGalleryFullscreenModal);

    const props: ImagesGalleryFullscreenModalProp = {
      slides: slidesData,
      index: index,
    };

    void modalFunction(props);
  }

  public removeMarkupFromPreviews(): void {
    const imageWrappers = this.getImageWrappers();

    if (!imageWrappers) {
      return;
    }

    for (let i = 0; i < this.qaAssets.length; i++) {
      const imageWrapper = imageWrappers[i];

      if (!imageWrapper) {
        continue;
      }

      const oldContainers = imageWrapper.getElementsByClassName(
        "_sketchpad-svg"
      );

      while (oldContainers.length > 0) oldContainers[0].remove();
    }
  }

  public shouldDisplayDownloadIconForQaAsset(qaAsset: QaAsset): boolean {
    return !this.isQaAssetDownloading(qaAsset);
  }

  private getImages(): HTMLImageElement[] | undefined {
    return this.$refs["image"] as HTMLImageElement[] | undefined;
  }

  private getImageWrappers(): HTMLElement[] | undefined {
    return this.$refs["imageWrapper"] as HTMLElement[] | undefined;
  }

  private isQaAssetDownloading(qaAsset: QaAsset): boolean {
    return !!this.fDownloadingQaAssets[qaAsset.id];
  }

  private async getPlushieData(plushieId: string) {
    const user = this.fUserProvider.getUser();

    const assets = await this.fQualityInspectionDataStore.loadQaAssetByPlushieId(
      {
        plushieId: plushieId,
      }
    );

    if (user && user.hasPermissionForResource(Resource.PHOTO_MARKUPS_READ)) {
      const imagesIds: string[] = [];

      assets.forEach((asset) => {
        if (isAssetVideo(asset)) {
          return;
        }

        imagesIds.push(asset.id);
      });

      setTimeout(() => {
        void this.loadMarkupData(imagesIds);
      }, 1000);
    }

    const storageItemsIds: string[] = [];
    assets.forEach((qaAsset) => {
      if (isAssetVideo(qaAsset)) {
        storageItemsIds.push(qaAsset.thumbnailStorageItem);
      }

      storageItemsIds.push(qaAsset.storageItem);
    });

    await this.fFileStorageDataStore.loadItemsByIds(storageItemsIds);

    this.$nextTick(() => {
      void this.preloadImages();
    });
  }

  private async loadMarkupData(imageIds: string[]): Promise<void> {
    await this.fPhotoMarkupDataStore.loadMarkupByImageIds({
      imageIds,
    });

    this.addMarkupToPreviews();
  }

  private async preloadImages() {
    await Promise.all(
      this.qaAssets.map((qaAsset) =>
        preloadImage(this.getFullscreenThumbnail(qaAsset))
      )
    );
  }

  @Watch("plushieId", { immediate: true })
  private async _onPlushieIdChange() {
    if (!this.plushieId) {
      return;
    }

    this.fIsLoading = true;

    await this.getPlushieData(this.plushieId);

    this.fIsLoading = false;

    await this.$nextTick();

    this.removeMarkupFromPreviews();
    this.addMarkupToPreviews();
  }
}
