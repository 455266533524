




























import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import EditableText from "@/modules/plushie/components/EditableText.vue";
import TrackingNumber from "@/lib/components/TrackingNumber.vue";

import dataStore from "@/store";
import PlushieStore from "@/modules/plushie/store";
import TrackingInformation from "@/modules/plushie/tracking-information.model";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import { Resource } from "@/modules/account/resource";

@Component({
  components: {
    EditableText,
    TrackingNumber,
  },
})
export default class PlushieShipmentInfo extends Vue {
  @Prop({ required: true })
  public readonly plushieId!: string;

  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  private fIsLoading = true;

  private fPlushieStore: PlushieStore;

  get canManageTrackingInformation(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.TRACKING_INFORMATION_MANAGE);
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get createdAt(): string {
    return this.trackingInformation?.createdAt?.toLocaleDateString() || "";
  }

  get showComponent(): boolean {
    return !this.isLoading && !!this.trackingNumber;
  }

  get trackingInformation(): TrackingInformation | undefined {
    return this.fPlushieStore.getTrackingInformationById(this.plushieId);
  }

  get trackingNumber(): string {
    return this.trackingInformation?.trackingNumber || "";
  }

  constructor() {
    super();

    this.fPlushieStore = getModule(PlushieStore, dataStore);
  }

  public async updateTrackingNumber(value?: string): Promise<void> {
    if (!value || !this.trackingInformation) {
      return;
    }

    const trackingInformation = new TrackingInformation(
      this.trackingInformation.id,
      value
    );

    trackingInformation.isNew = false;

    await this.fPlushieStore.saveTrackingInformation(trackingInformation);
  }

  private loadData(
    plushieId: string
  ): Promise<TrackingInformation | undefined> {
    return this.fPlushieStore.loadTrackingInformationById({
      id: plushieId,
    });
  }

  @Watch("plushieId", { immediate: true })
  private async _onPlushieIdChange(): Promise<void> {
    if (!this.plushieId) {
      return;
    }

    this.fIsLoading = true;

    try {
      await this.loadData(this.plushieId);
    } finally {
      this.fIsLoading = false;
    }
  }
}
