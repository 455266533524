export default class PlushieAttachmentHandlerService {
  private fApiHost: string;

  public constructor(apiHost: string) {
    this.fApiHost = apiHost;

    if (!this.fApiHost.endsWith("/")) {
      this.fApiHost += "/";
    }
  }

  public getOriginalAttachmentUrl(attachmentKey: string): string {
    return `${this.fApiHost}${attachmentKey}`;
  }
}
