



























import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import FormErrors from "@/lib/components/FormErrors.vue";
import FormField from "@/lib/components/FormField.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import rootStore from "@/store";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";
import ProductionProcessStore from "@/modules/production-process/store";

@Component({
  components: {
    FormErrors,
    FormField,
    SubmitButton,
  },
})
export default class SendToCustomerCommand extends mixins(GeneralFormMixin) {
  @Prop({ required: true })
  public readonly plushieId!: string;

  public trackingNumber?: string;

  private fProductionProcessStore: ProductionProcessStore;

  constructor() {
    super();
    this.fProductionProcessStore = getModule(ProductionProcessStore, rootStore);
  }

  protected data(): Record<string, string | undefined> {
    return {
      trackingNumber: this.trackingNumber,
    };
  }

  protected async performSubmit(): Promise<void> {
    if (!this.trackingNumber) {
      return;
    }

    await this.fProductionProcessStore.sendToCustomer({
      plushieId: this.plushieId,
      trackingNumber: this.trackingNumber,
    });
  }
}
