export default class VideoHandlerService {
  private fApiHost: string;

  public constructor(apiHost: string) {
    this.fApiHost = apiHost;

    if (!this.fApiHost.endsWith("/")) {
      this.fApiHost += "/";
    }
  }

  public getOriginalVideoUrl(videoUrl: string): string {
    return `${this.fApiHost}${videoUrl}`;
  }
}
